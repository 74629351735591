<template>
  <b-form-group>
    <label v-if="label" class="col-form-label mr-1 pt-0">{{
      `${label}:`
    }}</label>
    <i
      v-if="tooltipText"
      v-b-tooltip.hover
      :class="tooltipIcon"
      :title="tooltipText"
    />
    <div
      v-if="
        isMultiple &&
        groupWhenAllSelected &&
        allowSelectAll &&
        areAllOptionsSelected
      "
      class="c-filterSelectAll"
    >
      <div class="c-selectAllFacade" @click="deselectAllOptions">
        <span>{{ $t('all') }}</span>
        <i class="fas fa-times" />
      </div>
      <br />
    </div>
    <v-select
      v-if="
        !allowSelectAll ||
        !groupWhenAllSelected ||
        !isMultiple ||
        (isMultiple && !areAllOptionsSelected)
      "
      :id="id"
      class="c-filterSelect"
      :clearable="clearable"
      direction="top"
      :disabled="disabled"
      :label="fieldtext"
      :multiple="isMultiple"
      :name="name"
      :options="options"
      :placeholder="`-- ${$t(placeholder)} --`"
      :value="model"
      @input="onSelectionChanged"
    >
      <template v-slot:option="option">
        {{
          translate === undefined ? option[fieldtext] : $t(option[fieldtext])
        }}
        <i
          v-if="option.configured"
          class="selected-icon"
          :class="option.icon || icon"
        />
      </template>
      <template #selected-option="option">
        {{
          translate === undefined ? option[fieldtext] : $t(option[fieldtext])
        }}
        <i
          v-if="option.configured"
          class="selected-icon"
          :class="option.icon || icon"
        />
      </template>
    </v-select>
    <button
      v-if="
        isMultiple &&
        allowSelectAll &&
        !this.areAllOptionsSelected &&
        options.length
      "
      class="c-selectAllButton"
      @click="selectAllOptions"
    >
      {{ $t('selectall') }}
    </button>
    <error
      v-if="error"
      class="mt-n1"
      :message="errorMsg"
      variant="no-border no-background"
    />
  </b-form-group>
</template>

<script>
import vSelect from 'vue-select';
import Error from '@/components/basics/error/CustomError.vue';

export default {
  components: {
    vSelect,
    Error,
  },
  props: {
    id: {
      type: String,
      default: () => '',
      meta: {
        description: 'Id of the tag',
        example: 'ddlOffice',
      },
    },
    name: {
      type: String,
      default: () => '',
      meta: {
        description: 'Name of the tag',
        example: 'ddlOffice',
      },
    },
    label: {
      type: String,
      default: 'notavailable', // Taken from the locale dictionary
      required: true,
      meta: {
        description: 'Label text',
        example: 'Office',
      },
    },
    placeholder: {
      type: String,
      default: 'select', // Taken from the locale dictionary
      meta: {
        description: 'Texto de referencia para el CustomDropDown',
        example: 'Select',
      },
    },
    required: {
      type: String,
      default: function () {
        return '';
      },
      meta: {
        description: 'Required flag',
      },
    },
    error: {
      type: String,
      default: '',
      meta: {
        description: 'Error state',
      },
    },
    errorMsg: {
      type: String,
      default: () => '',
      meta: {
        description: 'Error Message',
        example: 'This field is required',
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      meta: {
        description: 'Disabled flag',
      },
    },
    options: {
      type: Array,
      default: () => [],
      meta: {
        description: 'Elements who can be chosen',
        example: '[4, 8, 15, 16, 23, 42]',
      },
    },
    value: {
      type: [Object, String, Number, Boolean, Array],
      required: false,
      default: () => null,
    },
    fieldvalue: {
      type: String,
      default: 'value',
      meta: {
        description: 'Field that contains the value that will be chosen',
        example: 'id',
      },
    },
    fieldtext: {
      type: String,
      default: 'text',
      meta: {
        description: 'Field that contains the text to display',
        example: 'name',
      },
    },
    clearable: {
      type: Boolean,
      default: () => true,
      meta: {
        description: 'Makes the clear button visible',
        example: 'true',
      },
    },
    returnObject: {
      type: Boolean,
      default: () => false,
    },
    icon: {
      type: String,
      default: () => '',
    },
    tooltipIcon: {
      type: String,
      default: () => 'fal fa-exclamation-circle',
    },
    translate: {
      type: String,
      default: () => undefined,
    },
    tooltipText: {
      type: String,
      default: () => '',
    },
    allowSelectAll: {
      type: Boolean,
      default: () => true,
    },
    groupWhenAllSelected: {
      type: Boolean,
      default: () => true,
    },
    isMultiple: {
      type: Boolean,
      default: () => false,
      meta: {
        description:
          'If the field is gonna be able to select multiple options or not',
        example: 'true',
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        let value = newVal;

        if (!this.returnObject) {
          if (this.isMultiple)
            value = this.options.filter((x) =>
              newVal.includes(x[this.fieldvalue]),
            );
          else value = this.options.find((x) => x[this.fieldvalue] == newVal);
        }
        this.model = value;
      },
    },
    options: function (values) {
      this.translateOptions();
      if (!this.returnObject && this.value) {
        if (this.isMultiple) {
          this.model = values.filter((x) =>
            this.value.includes(x[this.fieldvalue]),
          );
        } else
          this.model = values.find((x) => x[this.fieldvalue] == this.value);
      }
    },
  },
  data() {
    return {
      model: this.value,
    };
  },
  computed: {
    areAllOptionsSelected() {
      return this.options.length && this.value.length === this.options.length;
    },
  },
  mounted() {
    this.translateOptions();
  },
  methods: {
    translateOptions() {
      if (this.translate !== undefined)
        this.options.forEach(
          (option) =>
            (option[this.fieldtext] = this.$t(option[this.fieldtext])),
        );
    },
    onSelectionChanged(value) {
      this.$emit('on-selection-changed', value);
      this.$emit('change', value);
      let result = value;
      if (!this.returnObject && value)
        result = this.isMultiple
          ? value.map((x) => x[this.fieldvalue])
          : value[this.fieldvalue];

      this.$emit('input', result);
    },
    selectAllOptions() {
      this.onSelectionChanged(this.options);
    },
    deselectAllOptions() {
      this.onSelectionChanged([]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../node_modules/vue-select/dist/vue-select.css';
::v-deep .vs__dropdown-toggle {
  height: 39px;
}
.c-filterSelect {
  background-color: $color-white;
  border-radius: 4px;
  border: 1px solid $color-label-default;
  align-items: center;
  position: relative;

  ::v-deep .vs__dropdown-option.vs__dropdown-option--selected {
    display: none;
  }

  ::v-deep .vs__clear {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::v-deep .vs__dropdown-toggle {
    border: none;
    min-height: 39px;
    height: auto;
  }

  ::v-deep .vs__dropdown-option {
    text-overflow: ellipsis;
    overflow: hidden;
    border-top: solid $color-label-default 1px;
    white-space: normal;
  }

  ::v-deep .v-select {
    flex: 100% 1 1;
  }

  ::v-deep .vs__selected {
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    z-index: 2;
    flex-direction: row-reverse;
    button {
      margin-right: 5px;
      float: right;
    }
  }

  ::v-deep .vs__search {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0 !important;
    top: 0;
  }

  &.clock {
    ::v-deep .vs__selected-options {
      padding-left: 40px;
    }

    .icon {
      display: flex;
      align-items: center;
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
      /*ICONS IDENTIFIER*/
      &:after {
        content: '\f017';
        font-family: 'Font Awesome 5 Pro', sans-serif;
        font-size: 14px;
        color: $color-primary;
        margin-right: 13px;
      }
    }
  }
}

.c-filterSelectAll {
  background-color: $color-white;
  border-radius: 4px;
  border: 1px solid $color-label-default;
  display: flex;
  align-items: center;
  position: relative;
  min-height: 39px;

  .c-selectAllFacade {
    background-color: $color-white;
    border: 1px solid rgba(60, 60, 60, 0.26);
    border-radius: 4px;
    padding: 0 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 5px;

    i {
      color: rgba(60, 60, 60, 0.5);
      margin-left: 5px;
    }
  }
}

.c-selectAllButton {
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  margin-top: 10px;
  text-decoration: underline;
}
.selected-icon {
  margin: 0 5px;
  color: $color-font-success;
  font-size: 20px;
}
</style>
