<template>
  <div class="vdatetime-popup">
    <div class="vdatetime-popup__header">
      <div
        v-if="title && type !== 'time'"
        class="vdatetime-popup__title"
      >
        {{ title }}
      </div>
      <div
        v-if="type !== 'time'"
        class="vdatetime-popup__year"
        @click="showYear"
      >
        {{ year }}
      </div>
      <div
        v-if="type !== 'time'"
        class="vdatetime-popup__date"
        @click="showMonth"
      >
        {{ dateFormatted }}
      </div>
    </div>
    <div class="vdatetime-popup__body">
      <datetime-year-picker
        v-if="step === 'year'"
        :max-date="maxDatetime"
        :min-date="minDatetime"
        :year="year"
        @change="onChangeYear"
      />
      <datetime-month-picker
        v-if="step === 'month'"
        :max-date="maxDatetime"
        :min-date="minDatetime"
        :month="month"
        :year="year"
        @change="onChangeMonth"
      />
      <datetime-calendar
        v-if="step === 'date'"
        :day="day"
        :max-date="maxDatetime"
        :min-date="minDatetime"
        :month="month"
        :selected-week-days="selectedWeekDays"
        :week-start="weekStart"
        :year="year"
        @change="onChangeDate"
      />
      <datetime-time-picker
        v-if="step === 'time'"
        :hour="hour"
        :hour-step="hourStep"
        :max-time="maxTime"
        :min-time="minTime"
        :minute="minute"
        :minute-step="minuteStep"
        :use12-hour="use12Hour"
        @change="onChangeTime"
      />
    </div>
    <div class="vdatetime-popup__actions">
      <div
        class="vdatetime-popup__actions__button vdatetime-popup__actions__button--cancel"
        @click="cancel"
      >
        <slot
          name="button-cancel__internal"
          :step="step"
        >
          {{ phrases.cancel }}
        </slot>
      </div>
      <div
        class="vdatetime-popup__actions__button vdatetime-popup__actions__button--confirm"
        :class="{ disabled: !canConfirm }"
        @click="confirm"
      >
        <slot
          name="button-confirm__internal"
          :step="step"
        >
          {{ phrases.ok }}
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
import DatetimeTimePicker from '@/components/extensions/DatetimeTimePicker';
import DatetimeCalendar from '@/components/extensions/DatetimeCalendar';

import {
  DatetimePopup,
} from 'vue-datetime';

export default {
  extends: DatetimePopup,
  components: {
    DatetimeCalendar,
    DatetimeTimePicker,
  },
  props: {
    selectedWeekDays: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    canConfirm() {
      if (this.type === 'date') return true;
      const moment = this.$moment();

      let selectedTime = moment
        .set('hour', this.hour)
        .set('minute', this.minute);

      const maxTime = this.$moment(moment.toObject()).add(30, 'minutes');

      const result =
        selectedTime.isSame(moment) ||
        selectedTime.isSame(maxTime) ||
        selectedTime.isBetween(moment, maxTime);

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  color: $color-label-default !important;
}
.vdatetime-popup__year {
  font-size: 32px;
  font-weight: bold;
}
</style>
