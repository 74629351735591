<template>
  <div class="vdatetime">
    <slot name="before" />
    <input
      :id="inputId"
      v-bind="$attrs"
      class="vdatetime-input"
      :class="inputClass"
      :style="inputStyle"
      type="text"
      :value="inputValue"
      :disabled="disabled"
      v-on="$listeners"
      @click="open"
      @focus="open"
    />
    <input
      v-if="hiddenName"
      :name="hiddenName"
      type="hidden"
      :value="value"
      @input="setValue"
      :disabled="disabled"
    />
    <slot name="after" />
    <transition-group
      name="vdatetime-fade"
      tag="div"
    >
      <div
        v-if="isOpen && !hideBackdrop"
        key="overlay"
        class="vdatetime-overlay"
        @click.self="clickOutside"
      />
      <datetime-popup
        v-if="isOpen"
        key="popup"
        :auto="auto"
        :datetime="popupDate"
        :flow="flow"
        :hour-step="hourStep"
        :max-datetime="popupMaxDatetime"
        :min-datetime="popupMinDatetime"
        :minute-step="minuteStep"
        :phrases="phrases"
        :selected-week-days="selectedWeekDays"
        :title="title"
        :type="type"
        :use12-hour="use12Hour"
        :week-start="weekStart"
        @cancel="cancel"
        @confirm="confirm"
      >
        <template
          slot="button-cancel__internal"
          slot-scope="scope"
        >
          <slot
            name="button-cancel"
            :step="scope.step"
          >
            {{ phrases.cancel }}
          </slot>
        </template>
        <template
          slot="button-confirm__internal"
          slot-scope="scope"
        >
          <slot
            name="button-confirm"
            :step="scope.step"
          >
            {{ phrases.ok }}
          </slot>
        </template>
      </datetime-popup>
    </transition-group>
  </div>
</template>
<script>
import DatetimePopup from '@/components/extensions/DatetimePopup';

import { Datetime } from 'vue-datetime';

export default {
  extends: Datetime,
  components: {
    DatetimePopup
  },
  props: {
    selectedWeekDays: {
      type: Array,
      default:()=>[]
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  }
}
</script>
<style>
.vdatetime-input:disabled {
  background: #F8F8F8 !important;
}

</style>
