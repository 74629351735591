<template>
  <div :class="{'vdatetime-time-picker': true, 'vdatetime-time-picker__with-suffix': use12Hour}">
    <div
      ref="hourList"
      class="vdatetime-time-picker__list vdatetime-time-picker__list--hours"
    >
      <div
        v-for="item in availableHours"
        :key="item.number"
        class="vdatetime-time-picker__item"
        :class="{'vdatetime-time-picker__item--selected': item.selected, 'vdatetime-time-picker__item--disabled': item.disabled}"
        @click="selectHour(item)"
      >
        {{ formatHour(item.number) }}
      </div>
    </div>
    <div
      ref="minuteList"
      class="vdatetime-time-picker__list vdatetime-time-picker__list--minutes"
    >
      <div
        v-for="item in availableMinutes"
        :key="item.number"
        class="vdatetime-time-picker__item"
        :class="{'vdatetime-time-picker__item--selected': item.selected, 'vdatetime-time-picker__item--disabled': item.disabled}"
        @click="selectMinute(item)"
      >
        {{ item.number }}
      </div>
    </div>
    <div
      v-if="use12Hour"
      ref="suffixList"
      class="vdatetime-time-picker__list vdatetime-time-picker__list--suffix"
    >
      <div
        class="vdatetime-time-picker__item"
        :class="{'vdatetime-time-picker__item--selected': hour < 12}"
        @click="selectSuffix('am')"
      >
        am
      </div>
      <div
        class="vdatetime-time-picker__item"
        :class="{'vdatetime-time-picker__item--selected': hour >= 12}"
        @click="selectSuffix('pm')"
      >
        pm
      </div>
    </div>
  </div>
</template>

<script>
import { DatetimeTimePicker } from 'vue-datetime';

export default {
  extends: DatetimeTimePicker,

  props: {
    hour: {
      type: Number,
      required: true
    },
    minute: {
      type: Number,
      required: true
    },
    use12Hour: {
      type: Boolean,
      default: false
    },
    hourStep: {
      type: Number,
      default: 1
    },
    minuteStep: {
      type: Number,
      default: 1
    },
    minTime: {
      type: String,
      default: null
    },
    maxTime: {
      type: String,
      default: null
    }
  },

  computed: {
    availableHours () {
      return this.calcHours().filter(hour => {
        if (!this.use12Hour) {
          return true
        } else {
          if (this.hour < 12) {
            return hour < 12
          } else {
            return hour >= 12
          }
        }
      }).map(hour => ({
        number: this.calcPad(hour),
        selected: hour === this.hour,
        disabled: this.timeComponentIsDisabled(this.minHour, this.maxHour, hour)
      }))
    },
    availableMinutes () {
      return this.calcMinutes().map(minute => ({
        number: this.calcPad(minute),
        selected: minute === this.minute,
        disabled: this.timeComponentIsDisabled(this.minMinute, this.maxMinute, minute)
      }))
    },
    minHour () {
      return this.minTime ? parseInt(this.minTime.split(':')[0]) : null
    },
    minMinute () {
      return this.minTime && this.minHour === this.hour ? parseInt(this.minTime.split(':')[1]) : null
    },
    maxHour () {
      return this.maxTime ? parseInt(this.maxTime.split(':')[0]) : null
    },
    maxMinute () {
      return this.maxTime && this.maxHour === this.hour ? parseInt(this.maxTime.split(':')[1]) : null
    }
  },

  methods: {
    timeComponentIsDisabled (min, max, component) {
      return (min !== null && component < min) || (max !== null && component > max);
    },
    calcHours() {
      const self = this;
      
      return Array.apply(null, Array(Math.ceil(24 / self.hourStep))).map(function (item, index) { return index * self.hourStep; });
    },
    calcMinutes() {
      if(!this.minTime) {
        const self = this;
        
        const minArr = Array.apply(null, Array(Math.ceil(60 / self.minuteStep)));

        return minArr.map(function (item, index) { return index * self.minuteStep; });
      }

      let currentMinimumHour = parseInt(this.minTime.split(':')[0]);
      let currentMinimumMinute = parseInt(this.minTime.split(':')[1]);

      const result = [];

      while (currentMinimumHour <= this.hour) {
        if(currentMinimumHour == this.hour) {
          result.push(currentMinimumMinute);
        } 
        else if(currentMinimumHour > this.hour) {
          break;
        }

        currentMinimumMinute += this.minuteStep; 

        if (currentMinimumMinute >= 60) {
          const tMin = currentMinimumMinute;

          currentMinimumMinute = parseInt(tMin % 60);
          currentMinimumHour += parseInt(tMin / 60);
        }
      }

      return result;
    },
    calcPad (number) {
      return number < 10 ? '0' + number : number;
    },
    selectHour (hour) {
      if (hour.disabled) {
        return
      }

      this.$emit('change', { hour: parseInt(hour.number) })
    },
    selectMinute (minute) {
      if (minute.disabled) {
        return
      }

      this.$emit('change', { minute: parseInt(minute.number) })
    },
    selectSuffix (suffix) {
      if (suffix === 'am') {
        if (this.hour >= 12) {
          this.$emit('change', { hour: parseInt(this.hour - 12), suffixTouched: true })
        }
      }
      if (suffix === 'pm') {
        if (this.hour < 12) {
          this.$emit('change', { hour: parseInt(this.hour + 12), suffixTouched: true })
        }
      }
    },
    formatHour (hour) {
      const numHour = Number(hour)
      if (this.use12Hour) {
        if (numHour === 0) {
          return 12
        }
        if (numHour > 12) {
          return numHour - 12
        }
        return numHour
      }
      return hour
    }
  }
}
</script>