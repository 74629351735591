var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-form-group',[(_vm.label)?_c('label',{staticClass:"col-form-label mr-1 pt-0"},[_vm._v(_vm._s(`${_vm.label}:`))]):_vm._e(),(_vm.tooltipText)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:_vm.tooltipIcon,attrs:{"title":_vm.tooltipText}}):_vm._e(),(
      _vm.isMultiple &&
      _vm.groupWhenAllSelected &&
      _vm.allowSelectAll &&
      _vm.areAllOptionsSelected
    )?_c('div',{staticClass:"c-filterSelectAll"},[_c('div',{staticClass:"c-selectAllFacade",on:{"click":_vm.deselectAllOptions}},[_c('span',[_vm._v(_vm._s(_vm.$t('all')))]),_c('i',{staticClass:"fas fa-times"})]),_c('br')]):_vm._e(),(
      !_vm.allowSelectAll ||
      !_vm.groupWhenAllSelected ||
      !_vm.isMultiple ||
      (_vm.isMultiple && !_vm.areAllOptionsSelected)
    )?_c('v-select',{staticClass:"c-filterSelect",attrs:{"id":_vm.id,"clearable":_vm.clearable,"direction":"top","disabled":_vm.disabled,"label":_vm.fieldtext,"multiple":_vm.isMultiple,"name":_vm.name,"options":_vm.options,"placeholder":`-- ${_vm.$t(_vm.placeholder)} --`,"value":_vm.model},on:{"input":_vm.onSelectionChanged},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.translate === undefined ? option[_vm.fieldtext] : _vm.$t(option[_vm.fieldtext]))+" "),(option.configured)?_c('i',{staticClass:"selected-icon",class:option.icon || _vm.icon}):_vm._e()]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.translate === undefined ? option[_vm.fieldtext] : _vm.$t(option[_vm.fieldtext]))+" "),(option.configured)?_c('i',{staticClass:"selected-icon",class:option.icon || _vm.icon}):_vm._e()]}}],null,false,2722697421)}):_vm._e(),(
      _vm.isMultiple &&
      _vm.allowSelectAll &&
      !this.areAllOptionsSelected &&
      _vm.options.length
    )?_c('button',{staticClass:"c-selectAllButton",on:{"click":_vm.selectAllOptions}},[_vm._v(" "+_vm._s(_vm.$t('selectall'))+" ")]):_vm._e(),(_vm.error)?_c('error',{staticClass:"mt-n1",attrs:{"message":_vm.errorMsg,"variant":"no-border no-background"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }