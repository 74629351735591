<template>
  <b-form-group class="c-filterMultiSelect">
    <template :v-slot="label">
      <div class="c-s-filterLabel">
        <span>
          {{ this.title != null ? this.title + ':' : null }}
        </span>
        <span
          v-if="required"
          class="text-danger"
        >*</span>
      </div>
    </template>
    <datetime
      :key="key"
      v-model="selectedValue"
      :backdrop-click="backdropClick"
      class="theme-orange"
      :disabled="disabled"
      :format="formattedInputValue()"
      :hidden-name="hiddenName"
      :hide-backdrop="hideBackdrop"
      :hour-step="hourStep"
      :input-class="inputClass"
      :input-id="inputId"
      :input-style="inputStyle"
      :max-datetime="maxDatetime"
      :min-datetime="minDatetime"
      :minute-step="minuteStep"
      :phrases="phrases"
      :readonly="isReadOnly == true"
      :selected-week-days="selectedWeekDays"
      :title="title"
      :type="type"
      use12-hour
      :value-zone="zone"
      :week-start="7"
      :zone="zone"
      @input="updateValueEvent"
    >
      <template slot="before">
        <b-icon
          class="custom-form-input-icon"
          :icon="iconClass"
        />
      </template>
       <template slot="after">
        <i v-if="selectedValue && !disabled && clearable" class="fal fa-times icon-close" @click="onClean"></i>
      </template>
    </datetime>
    <error
      v-if="error"
      class="mt-n1"
      :message="errorMsg"
      variant="no-border no-background"
    />
  </b-form-group>
</template>
<script>
import 'vue-datetime/dist/vue-datetime.css';
import Datetime from '@/components/extensions/Datetime';
import Error from '@/components/basics/error/CustomError.vue';

export default {
  name: 'BaseDatetimePicker',
  components: {
    Datetime,
    Error
  },
  data() {
    return {
      formattedInputValue() {
        if (this.type == 'time') {
          return this.format == null ? 'hh:mm a' : this.format;
        } else if (this.type == 'date') {
          return this.format == null ? 'dd/MMM/yyyy' : this.format;
        }
      },
      selectedValue: this.value,
      initialValueSetted: this.setInitialValue,
      key: 0
    };
  },
  watch: {
    value: function (value) {
      this.selectedValue = value;
    },
  },
  methods: {
    updateValueEvent: function () {
      if (this.initialValueSetted) {
        this.$emit('change', this.selectedValue);
      } else {
        this.initialValueSetted = true;
      }
    },
    onClean() {
      this.$emit('change', null);
      this.selectedValue = null;
      this.key++;
    }
  },
  mounted() {},
  inheritAttrs: false,
  props: {
    iconClass: {
      type: String,
      default: 'calendar3',
    },
    zone: {
      type: String,
      default: 'local',
    },
    inputId: {
      type: String,
      default: null,
    },
    inputClass: {
      type: [Object, Array, String],
      default: '',
    },
    inputStyle: {
      type: [Object, Array, String],
      default: '',
    },
    hiddenName: {
      type: String,
      default: '',
    },
    format: {
      type: [Object, String],
      default: null,
    },
    type: {
      type: String,
      default: 'date',
    },
    phrases: {
      type: Object,
      default() {
        return {
          cancel: 'Cancel',
          ok: 'Ok',
        };
      },
    },
    hourStep: {
      type: Number,
      default: 1,
    },
    minuteStep: {
      type: Number,
      default: 1,
    },
    minDatetime: {
      type: String,
      default: null,
    },
    maxDatetime: {
      type: String,
      default: null,
    },
    auto: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: () => null,
    },
    hideBackdrop: {
      type: Boolean,
      default: false,
    },
    backdropClick: {
      type: Boolean,
      default: true,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: () => null,
    },
    setInitialValue: {
      type: Boolean,
      default: true,
    },
    selectedWeekDays: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: function () {
        return false;
      },
      meta: {
        description: 'Required flag',
      },
    },
     error: {
      type: String,
      default: '',
      meta: {
        description: 'Error state',
      },
    },
    errorMsg: {
      type: String,
      default: () => '',
      meta: {
        description: 'Error Message',
        example: 'This field is required',
      },
    },
    clearable: {
      type: Boolean,
      default: () => true,
      meta: {
        description: 'Makes the clear button visible',
        example: 'true',
      },
    },
  },
};
</script>

<style lang="scss">
.theme-orange .vdatetime-popup__header,
.theme-orange .vdatetime-calendar__month__day--selected > span > span,
.theme-orange .vdatetime-calendar__month__day--selected:hover > span > span {
  background: $color-primary;
}

.theme-orange .vdatetime-year-picker__item--selected,
.theme-orange .vdatetime-time-picker__item--selected,
.theme-orange .vdatetime-popup__actions__button {
  color: $color-primary;
}

.vdatetime-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $color-font-secondary;
  background-color: $color-white;
  background-clip: padding-box;
  border: 1px solid $color-label-default;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding-left: 36px;
}

.custom-form-input-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  z-index: 10;
  color: $color-primary;
}

.vdatetime-time-picker__list::before {
  color: $color-white;
  position: absolute;
  display: block;
  margin-bottom: 5px;
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  transition: font-size 0.3s;
  box-sizing: border-box;
  top: 0px;
  margin-left: 50px;
}
.vdatetime-time-picker__list.vdatetime-time-picker__list--hours::before {
  content: 'Horas';
  margin-left: 60px;
}
.vdatetime-time-picker__list--minutes::before {
  content: 'Minutos';
}

.vdatetime-time-picker.vdatetime-time-picker__with-suffix ::before {
  margin-left: 20px;
}

.vdatetime-time-picker.vdatetime-time-picker__with-suffix
  .vdatetime-time-picker__list.vdatetime-time-picker__list--hours::before {
  margin-left: 30px;
}

.icon-close {
  position: absolute;
  right: 30px;
  font-size: 20px;
  z-index: 10;
  top: 16px;
  cursor: pointer;
  color: #A3A3A3;
  top: 37px;
}
</style>
